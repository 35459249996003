@import "src/app/styles/index.scss";

.content {
	display: flex;
	//flex-wrap: wrap;
	align-items: start;
	justify-content: start;
	gap: 20px;

	color: white;
	font-family: $mainFontFamily;
}

.tabs {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 14px;
	
	margin-top: 50px;
	padding: 20px;
}

.tab {
	& > button {
		cursor: pointer;

		width: 163px;
		height: 30px;

		background: $tabsBg;
		border: none;
		border-radius: 10px;

		color: white;
		font-family: $mainFontFamily;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;

		&:hover {
			background: $tabsBgHover;
		}
	}
}

.mainContent {
	max-width: calc(100vh - 120px);
}
