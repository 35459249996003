@import "src/app/styles/index.scss";

.content {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 14px;

	color: white;

	padding: 20px;
	margin-top: 50px;

	font-family: $mainFontFamily;
}

.block {
	display: flex;
	flex-direction: column;
	gap: 14px;
}

.message {
	height: 100px !important;

	padding-top: 10px !important;
	padding-bottom: 10px !important;
}

.title {
	margin-bottom: 5px;
}

.btn {
	width: 145px;
	height: 44px;
	background: $btnBgNeobox;
	border-radius: 32px;
	border: 2px $btnBorderNeobox solid;

	display: grid;
	justify-content: center;
	align-items: center;

	grid-auto-flow: column;
	column-gap: 8px;

	font-family: $mainFontFamily;
	color: $btnColorNeobox;

	cursor: pointer;
}

.input {
	width: 100%;
	height: 48px;
	background: transparent;
	outline: none;

	padding: 0 29px;
	font-family: $subMainFontFamily;
	font-style: normal;
	font-weight: 400;
	line-height: 140%;

	color: $colorMainText;

	border-radius: 32px;
	border: 2px $btnBorderNeobox solid;
	background: $inputBgNeobox;
}

.generateBlock {
	display: flex;
	align-items: center;
	gap: 14px;
}

.generateText {
	border-radius: 32px;
	border: 2px $btnBorderNeobox solid;
	background: $generateTextBg;

	width: 300px;
	padding: 10px;
}
