//Home
$mainFontFamily: "superstar";
$subMainFontFamily: "Manrope";

$colorMainText: #fff;

$tabsBg: linear-gradient(84deg, #362648 9.87%, #382843 89.86%);
$tabsBgHover: linear-gradient(
	270deg,
	rgba(253, 158, 18, 0) 0.06%,
	rgba(253, 158, 18, 0.6) 49.35%,
	rgba(253, 158, 18, 0) 99.93%
);

$btnBorder: rgba(42, 135, 33, 0.4);
$btnBorderNeobox: rgba(124, 248, 196, 0.4);
$btnBorderCore: rgba(254, 154, 61, 0.4);

$btnBg: #151515;
$btnBgNeobox: #7cf8c4;
$btnBgCore: #FF9A3D;

$btnColor: #fff;
$btnColorNeobox: #373737;
$btnColorCore: #373737;

$generateTextBg: rgba(50, 54, 49, 0.3);
$generateTextBgNeobox: rgba(50, 54, 49, 0.3);
$generateTextBgCore: rgba(50, 54, 49, 0.3);

$scrollBarThumb: linear-gradient(to bottom, #ebb16d, #d67200af);
$scrollBarTrack: rgba(255, 255, 255, 0.3);
$selectBg: #292542;

$inputBg: #323631;
$inputBgNeobox: #012e3f;
$inputBgCore: #4E3116;

