@import "normalize.scss";
@import "media";
@import "main";
@import "animations";

body {
	background: #00242F;
	//overflow-x: hidden;
}

